"use client";
import UnProtectedPage from "#/components/layout/unprotected-page";
import { PosAuthView } from "#/components/onboarding/pos-auth-view";
import { useTStream } from "#/hooks/use-t-stream";
import { ThemeContext } from "#/providers/theme-provider";
import Link from "next/link";
import { useContext } from "react";

export default function WelcomePageClient() {
  const { t } = useTStream("common");
  const theme = useContext(ThemeContext);
  return (
    <UnProtectedPage>
      <div className="flex flex-2  h-[100vh] items-center justify-center">
        <div className="flex h-full w-[80%] items-center justify-evenly max-w-[500px] max-h-[400px] flex-col">
          <p className="text-center text-5xl text-foreground font-500">
            {t("Welcome")}
          </p>
          <p className="text-center text-3xl text-foreground font-500">
            {t("require_login", {
              theme: theme.name,
            })}
          </p>
          {theme.portalLink && (
            <Link
              className="mt-2 w-full text-center text-primary text-sm font-500"
              href={theme.portalLink || "#"}
            >
              {t("Take me there")}
            </Link>
          )}
          <div className="flex flex-col">
            <PosAuthView />
          </div>
        </div>
      </div>
    </UnProtectedPage>
  );
}
